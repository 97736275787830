#video-area, #video {
    width: 100%;
    height: calc(100% - 18px);
    /* can't set height:100% here because the height is controlled automatically
       to maintain the aspect ratio. */
}

html, .acorn-controls {
    min-width: 310px;
    vertical-align: center;
}

.acorn-player.bigbluebutton {
    width: 100%;
    height: 100%;
    position: static;
}

#deskshare-video {
    visibility: hidden;
    width: 100%;
    height: calc(100% - 30px);
    margin: 0 auto;
    z-index: 25;
}

/* The playback bar is moved out of the video/audio area into a more
   generic place in the page, making it look like the controls are not
   only for audio and video, but for all playback. */
#audio-area .acorn-controls, #video-area .acorn-controls {
    position: absolute;
    bottom: 0;
    left: 0;
    width: calc(100% - 40px);
    z-index: 99;
}

#video, #deskshare-video {
    background: none;
}

#loading {
    visibility: hidden;
    width: 100%;
    height: 100%;
}

#load-msg {
    font-size: 1.45rem;
    color: #666666;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    vertical-align: middle;
    position: absolute;
    bottom: 30px;
    font-weight: bold;
    width: 100%;
    left: 0;
    right: 0;
}


/* Presentation and related elements */
#slide {
    background-size: 160px 160px;
    background-repeat: no-repeat;
    background-position: center center;
}
#slide.no-background {
    background-size: 0px 0px;
}
#slide svg {
    width: 100%;
    height: auto;
}
#cursor {
    visibility: hidden;
    width: 12px;
    height: 12px;
    transform: translate(-6px, -6px);
    border-radius: 50%;
    position: fixed;
    background: red;
    z-index: 10;
}

/* Thumbnails */
#thumbnails {
    padding: 10px;
    text-align: center;
}
#thumbnails .thumbnail-wrapper {
    position: relative;
    margin: 0;
    padding: 5px 0;
    cursor: pointer;
}
#thumbnails .thumbnail-wrapper.active,
#thumbnails .thumbnail-wrapper.active:hover {
    background-color: #D9EDF7;
}
#thumbnails .thumbnail-wrapper.active img,
#thumbnails .thumbnail-wrapper.active:hover img {
    border-color: #289ad6; /* #314b5d; */
}
#thumbnails .thumbnail-wrapper:hover img {
    border-color: #289ad6; /* #314b5d; */
}
#thumbnails img {
    max-height: 125px;
    border: 1px solid #eee;
}
#thumbnails .thumbnail-wrapper.hovered .thumbnail-label,
#thumbnails .thumbnail-wrapper.active .thumbnail-label {
    display: block;
}
#thumbnails .thumbnail-label {
    color: #fff;
    background: #289ad6;
    font-weight: bold;
    font-size: 12px;
    position: absolute;
    bottom: 5px;
    left: 0;
    right: 0;
    width: 50%;
    margin: 0 auto;
    text-align: center;
    display: none;
    padding: 2px 5px;
}

/* Sliding sidebar */
.inner-wrap {
    height: 100%;
}
.left-off-canvas-menu {
    background: #fff;
    width: 13rem;
    border-right: 1px solid #e2e2e2;
}
.move-right > .inner-wrap {
    -webkit-transform: translate3d(13rem, 0, 0);
    -moz-transform: translate3d(13rem, 0, 0);
    -ms-transform: translate(13rem, 0);
    -o-transform: translate3d(13rem, 0, 0);
    transform: translate3d(13rem, 0, 0);
}
ul.off-canvas-list li label {
    background: #2a2d34;
    color: #eee;
}
.exit-off-canvas {
    box-shadow: none !important;
}

.visually-hidden {
    position: absolute !important;
    clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
    clip: rect(1px, 1px, 1px, 1px);
    padding: 0 !important;
    border: 0 !important;
    height: 1px !important;
    width: 1px !important;
    overflow: hidden;
}
